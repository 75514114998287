(function ($) {
    $(function () {
        /* Dynamic Cursor follower */
        if(window.matchMedia('(min-width: 1024px)').matches){
            let ac_section_selector = $('.AnimatedCursor');

            ac_section_selector.map(function () {
                let ac_section_id = $(this).attr('id');
                let ac_data_node = $(this).data('node');
    
                let ac_unique_id = ac_section_id ? ac_section_id : ac_data_node;
    
                let ac_unique_selector = ac_section_id ? '#' + ac_section_id : '.fl-node-' + ac_data_node;
    
                if (ac_unique_selector) {
                    let section = $(ac_unique_selector);
                    
                    let section_classes = section.prop('class');
                    let target = section_classes.split('target-')[1] ?? 'CustomPostCard';
                    target = target.split(' ')[0];
                    let msg = section_classes.split('msg-')[1] ?? '';
                    msg = msg.split(' ')[0];

                    let cursor = '.custom-cursor-animation';
                    if($(cursor).length === 0){
                        $('body').append(createElement(msg));
                    }else{
                        if(!$(cursor).hasClass('custom-cursor-animation-light')){
                            $(cursor).addClass('custom-cursor-animation-light');
                        }
                    }
                    handleCursor(cursor, target, ac_unique_selector, msg);
                } else {
                    console.log('Selectors are missing. Please add an ID to the target element.');
                }
            });
    
            function createElement(msg) {
                let el = `<div class="custom-cursor-animation">${msg ?? 'view'}</div>`;
                return el;
            }
    
            function handleCursor(cursor, target, section, msg) {
                $(section).mousemove(function (event) {
                    let csr = document.querySelector(cursor);
                    const { clientX, clientY } = event;
                    let light_cls = $(section).hasClass('cursor-light') ? 'custom-cursor-animation-light' : '';
                    if($(csr).text() != msg){
                        $(csr).text(msg);
                    }
                    if(light_cls !== ''){
                        if(!$(csr).hasClass('custom-cursor-animation-light')){
                            $(csr).addClass(light_cls);
                        }
                    }else{
                        if($(csr).hasClass('custom-cursor-animation-light')){
                            $(csr).removeClass('custom-cursor-animation-light');
                        }                    
                    }
                    
                    if($('.'+target+':hover').length > 0){
                        csr.animate({
                            left: `${clientX + 10}px`,
                            top: `${clientY + 10}px`,
                            opacity: 1,
                            scale: 1.2,
                        
                        }, {duration: 500, fill: "forwards"});
                    }else{
                        csr.animate({
                            opacity: 0,
                            left: `${clientX}px`,
                            top: `${clientY}px`,
                            scale: 0.5,
                        }, {duration: 500, fill: "forwards"});
                    }
    
                });
    
                $(section).mouseleave(function(event){
                    
                    let csr = document.querySelector(cursor);
                    const { clientX, clientY } = event;
    
                    csr.animate({
                        opacity: 0,
                        left: `${clientX}px`,
                        top: `${clientY}px`,
                        scale: 0.5,
                    }, {duration: 200, fill: "forwards"});
                })
            }
        }
        
    });
})(jQuery);