(function($){
    $(function(){
        gsap.registerPlugin(ScrollTrigger);

        let tl = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: '.animate',
                start: 'top top', // when the top of the trigger hits the top of the viewport
                end: '+=500', // end after scrolling 500px beyond the start
                markers: false,
            }
        });

        tl.from('.animate-from-right', {
            left: '50%'
        });
    });
})(jQuery);